import './scripts/common';

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};
import {onFind} from "@elements/init-modules-in-scope";

import ScrollSpy from 'bootstrap/js/dist/scrollspy';
onFind('[data-bs-spy="scroll"]',function (element) {
    new ScrollSpy(element);
});

import * as experienceCardTeaserSlider from './scripts/experience-card-teaser-slider';
experienceCardTeaserSlider.init();

import * as mixedCardTeaserSlider from './scripts/mixed-card-teaser-slider';
mixedCardTeaserSlider.init();

import * as experienceAreaAffix from './scripts/elements-packages/affix';
experienceAreaAffix.init();

import * as experienceArea from "./scripts/experience-area";
experienceArea.init();

import * as fadeIn from './scripts/fade-in';
fadeIn.init();

import * as imageMouseMoveAnimation from './scripts/image-mouse-move-animation';
imageMouseMoveAnimation.init();