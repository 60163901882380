"use strict";

import {onFind} from "@elements/init-modules-in-scope";
import {addClass,removeClass,hasClass,find,on} from '@elements/dom-utils';


export function init() {

    onFind('.js-experience-area', function (baseElement) {
        // Options for the Intersection Observer
        let options = {
            root: null, // Use the viewport as the root
            rootMargin: '-200px', // No margin
            // threshold: [1, 0.1, 0.6]
            threshold: [0.05, 0]
        };

        if(window.matchMedia("(max-width: 767px)").matches){
            options = {
                root: null, // Use the viewport as the root
                rootMargin: '0px', // No margin
                // threshold: [0.05, 0.1, 0.6]
                threshold: [0.05]
            };
        }

        // Create the Intersection Observer
        const observer = new IntersectionObserver(onEnterViewport, options);

        // onEnterViewport(baseElement);
        // Start observing the element
        observer.observe(baseElement);

    });
}

function onEnterViewport(element, observer) {

    if (element[0].isIntersecting) {
        // Element entered the viewport

        addClass('is-active', element[0].target);
        addClass('experience-is-active', find('body'));
    }  else if (element[0].intersectionRatio >= 0.1) {
        // Call your callback for element partially in viewport here
    } else {
        // Element left the viewport
        removeClass('is-active', element[0].target);
        removeClass('experience-is-active', find('body'));
    }
}

