import {onFind} from "@elements/init-modules-in-scope";
import {findIn} from '@elements/dom-utils';

export function init () {
    if (!matchMedia('(min-width: 768px) and (pointer: fine)').matches) {
        console.log("matched");
        return;
    }


    onFind('.js-image-mouse-move-animation', function (element) {
        let mouseX, mouseY;
        let bgFollowX = 0,
            bgFollowY = 0,
            moveX = 0,
            moveY = 0,
            friction = 1 / 30;

        function executeFrame() {
            requestAnimationFrame(executeFrame);
            moveBackground();
        }

        function moveBackground() {
            moveX += (bgFollowX - moveX) * friction;
            moveY += (bgFollowY - moveY) * friction;
            let translate = 'translate(' + moveX + 'px, ' + moveY + 'px) scale(1.1)';

            findIn('.js-image-mouse-move-animation__image', element).style.transform = translate;
            // findIn('.js-image-mouse-move-animation', element).css({
            //     'transform': translate
            // });
        }

        element.addEventListener("mousemove", function (evt) {
            mouseX = evt.clientX/window.innerWidth  * 100 - 50;
            mouseY = evt.clientY/window.innerHeight  * 100;

            let bgMouseX = Math.max(-100, Math.min(100, window.innerWidth / 2 - evt.clientX));
            let bgMouseY = Math.max(-100, Math.min(100, window.innerHeight / 2 - evt.clientY));


            bgFollowX = (20 * bgMouseX) / 100;
            bgFollowY = (10 * bgMouseY) / 100;
        });


        executeFrame();

    });


}