import {onFind} from "@elements/init-modules-in-scope";
import BlazeSlider from 'blaze-slider';
import {find, addClass} from "@elements/dom-utils";

export function init() {
    onFind('.js-mixed-card-teaser-slider', function (baseElement) {
        //Workaround to set slidesToShow to given values as blaze-slider overwrites slidesToShow if number of items is less than slidesToShow
        const slidesToShow = 3.9;
        const slidesToShow1300 = 3.2;
        const slidesToShow767 = 1.1;
        let style = document.createElement('style');

        let slideToShowValue = slidesToShow;
        if(window.matchMedia('(max-width: 767px)').matches) {
            slideToShowValue = slidesToShow767;
        }else if(window.matchMedia('(max-width: 1300px)').matches) {
            slideToShowValue = slidesToShow1300;
        }

        style.id = 'mixed-card-teaser-slider';
        style.innerHTML = '.slidesToShow--mixedCardTeaser { --slides-to-show: ' + slideToShowValue + ' !important; }';
        let head = find('head');
        if (!find('#' + style.id, head)) {
            head.appendChild(style);
        }

        new BlazeSlider(baseElement, {
            all: {
                loop: false,
                slidesToScroll: 1,
                slideGap: '8px',
                slidesToShow: slidesToShow,
                draggable: true,
            },
            '(max-width: 1300px)': {
                slidesToShow: slidesToShow1300,
            },
            '(max-width: 767px)': {
                slidesToShow: slidesToShow767
            },
        });
        addClass('slidesToShow--mixedCardTeaser', baseElement);
    });
}