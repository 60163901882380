import {onFind} from "@elements/init-modules-in-scope";
import BlazeSlider from 'blaze-slider';
import {addClass, find} from "@elements/dom-utils";

export function init() {
    onFind('.js-experience-card-teaser-slider', function (baseElement) {
        //Workaround to set slidesToShow to given values as blaze-slider overwrites slidesToShow if number of items is less than slidesToShow
        const slidesToShow = 3;
        const slidesToShow767 = 1.1;
        const maxWidth = '(max-width: 767px)';
        let style = document.createElement('style');
        style.id = 'experience-card-teaser-slider';
        style.innerHTML = '.slidesToShow--experienceCardTeaser { --slides-to-show: ' + (window.matchMedia(maxWidth).matches ? slidesToShow767 : slidesToShow) + ' !important; }';
        let head = find('head');
        if (!find('#' + style.id, head)) {
            head.appendChild(style);
        }

        new BlazeSlider(baseElement, {
            all: {
                loop: false,
                slidesToScroll: 1,
                slideGap: '8px',
                slidesToShow: slidesToShow,
                draggable: true,
            },
            '(max-width: 767px)': {
                slidesToShow: slidesToShow767,
            },
        });
        addClass('slidesToShow--experienceCardTeaser', baseElement);
    });
}